import React, { useState, useEffect } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import { ShoppingBasket } from "@mui/icons-material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useStateValue } from "./StateProvider";
import { useHistory } from "react-router-dom";
import { signOut } from "./firebase";
import MenuIcon from "@mui/icons-material/Menu"; // Импорт иконки меню

function Header() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [activeLink, setActiveLink] = useState(null);
  const history = useHistory();

  const handleActiveLink = (e) => {
    setActiveLink(e.target.id);
  };

  const handleAuthentication = () => {
    if (user) {
      signOut()
        .then(() => {
          dispatch({
            type: "SET_USER",
            user: null,
          });
          history.push("/"); // Optional: Redirect to homepage on sign out
        })
        .catch((error) => {
          // Обрабатываем возможную ошибку при выходе
          console.error("Sign Out Error", error);
        });
    }
  };
  const [menuOpen, setMenuOpen] = useState(false); // Добавленное состояние для меню

  // Функция для переключения состояния меню
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const [searchActive, setSearchActive] = useState(false);

  const handleSearchClick = () => {
    setSearchActive(!searchActive); // Если поле ввода активно, убрать active класс, иначе добавить
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Закрыть поле поиска при клике вне его области (необязательно)
  useEffect(() => {
    function handleOutsideClick(event) {
      if (searchActive && !event.target.closest(".header__search")) {
        setSearchActive(false);
      }
      if (
        menuOpen &&
        !event.target.closest(".header__menuIcon") &&
        !event.target.closest(".header__mobileNav")
      ) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchActive, menuOpen]); // массив зависимостей содержит обе переменные состояния

  // Определение, должен ли логотип и блок логина быть скрыты
  const shouldHideLogoAndLogin = searchActive && isMobile;

  return (
    <div className="header">
      <Link to="/" className={shouldHideLogoAndLogin ? "mobile-hidden" : ""}>
        <img className="header_logo" src="/images/goalogo2.jpg" alt="" />
      </Link>

      <div className={`header__search ${searchActive ? "active" : ""}`}>
        <input className="header__searchInput" type="text" />
        <SearchIcon
          className="header__searchIcon"
          onClick={handleSearchClick}
        />
      </div>
      <MenuIcon className="header__menuIcon" onClick={handleMenuToggle} />
      <div className="header__menu">
        <div
          id="events"
          className={
            activeLink === "events" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Events
        </div>
        <div
          id="marketplace"
          className={
            activeLink === "marketplace"
              ? "header__option active"
              : "header__option"
          }
          onClick={handleActiveLink}
        >
          Marketplace
        </div>
        <div
          id="hotels"
          className={
            activeLink === "hotels" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Hotels
        </div>
        <div
          id="tourism"
          className={
            activeLink === "tourism"
              ? "header__option active"
              : "header__option"
          }
          onClick={handleActiveLink}
        >
          Tourism
        </div>
        <div
          id="news"
          className={
            activeLink === "news" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          News
        </div>
        <div
          id="forum"
          className={
            activeLink === "forum" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Forum
        </div>
      </div>
      <Link to={!user && "/login"}>
        <div
          className={`header__login ${
            shouldHideLogoAndLogin ? "mobile-hidden" : ""
          }`}
        >
          <div onClick={handleAuthentication} className="header__login">
            <span className="header__optionLineOne">
              Hi {user ? user.name : "Guest"}
            </span>
            <span className="header__optionLineTwo">
              {user ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </div>
      </Link>
      <Link to="/checkout">
        <div className="header__optionBasket">
          <ShoppingBasket />
          <span className="header__optionLineTwo header__basketCount">
            {basket?.length}
          </span>
        </div>
      </Link>
      {/* Гамбургер-меню, которое отображается когда menuOpen == true */}
      {menuOpen && (
        <div className="header__mobileNav">
          <div
            id="events"
            className="header__option"
            onClick={handleMenuToggle}
          >
            Events
          </div>
          <div
            id="marketplace"
            className="header__option"
            onClick={handleMenuToggle}
          >
            Marketplace
          </div>
          <div
            id="hotels"
            className="header__option"
            onClick={handleMenuToggle}
          >
            Hotels
          </div>
          <div
            id="tourism"
            className="header__option"
            onClick={handleMenuToggle}
          >
            Tourism
          </div>
          <div id="news" className="header__option" onClick={handleMenuToggle}>
            News
          </div>
          <div id="forum" className="header__option" onClick={handleMenuToggle}>
            Forum
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
